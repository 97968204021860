export type DateTimeFormat = {
  name: string;
  /** id/key, stored on db */
  short: string;
  medium: string;
  long: string;
  full: string;
};

export const timeFormats: DateTimeFormat[] = [
  {
    name: '24 hour',
    short: 'HH:mm',
    medium: 'HH:mm:ss',
    long: 'HH:mm:ss z',
    full: 'HH:mm:ss zzzz',
  },
  {
    name: '12 hour',
    short: 'h:mm a',
    medium: 'h:mm:ss a',
    long: 'h:mm:ss a z',
    full: 'h:mm:ss a zzzz',
  },
];

export const dateFormats: DateTimeFormat[] = [
  {
    name: 'Day Month Year',
    short: 'd/M -yy',
    medium: 'd MMM, y',
    long: 'E, d MMMM, y',
    full: 'EEEE, d MMMM, y',
  },
  {
    name: 'Year Month Day',
    short: 'yy-M-d',
    medium: 'y, MMM d',
    long: 'y, MMMM d, E',
    full: 'EEEE, y, MMMM d',
  },
  {
    name: 'Month Day Year',
    short: 'M/d/yy',
    medium: 'MMM d, -yy',
    long: 'E, MMMM d, y',
    full: 'EEEE, MMMM d, y',
  },
];
