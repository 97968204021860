import { IEnvironment } from './interface/IEnvironment';

export const environment: IEnvironment = {
  production: false,
  envName: 'staging',
  secret: 'b0lb!ts',
  urls: {
    designageApi: 'https://api-staging.designage.io',
    dataBucket: 'https://data-staging.designage.io',
    auth0: 'dev-designage.eu.auth0.com',
    auth0CustomDomain: 'login.designage.se',
    designageApp: 'https://staging-app.designage.io',
    watchtowerApp: 'https://staging-watchtower.designage.io',

    get designageGQLEndpoint() {
      return `${this.designageApi}/graphql`;
    },

    get designageApiEndpoint() {
      return `${this.designageApi}/api/v1`;
    },

    get designageWSGQLEndpoint() {
      return `wss${this.designageApi.substr(
        this.designageApi.indexOf('://')
      )}/graphql`;
    },
  },
  auth0: {
    clientId: {
      designage: 'jGRgIeGhgwUpyjv62u7t8jx1rEMgWdz7',
      watchtower: '24EyqJjctE8DEaKM3rX8s6G1FYN7kgVS',
    },
    apiAudience: 'https://designage.com/',
    useRefreshToken: true,
    cacheLocation: 'localstorage',
  },
  assets: {
    placeholderImage: 'assets/images/placeholder-image.jpg',
    missingImagePlaceholder: 'assets/images/missing.png',
    missingScreenshotPlaceholder: 'assets/images/missingScreenshot.jpg',
    rawPlaceholder: 'assets/images/raw.png',
    logoSmall: 'assets/images/designage_sm.png',
    detachIcon: 'assets/images/preview-icons/detach.png',
    detachedWindowIcon: 'assets/images/preview-icons/detached-window.png',
    muteIcon: 'assets/images/preview-icons/mute.png',
    pauseIcon: 'assets/images/preview-icons/pause.png',
    playIcon: 'assets/images/preview-icons/play.png',
    unmuteIcon: 'assets/images/preview-icons/unmute.png',
    fullScreenIcon: 'assets/images/preview-icons/fullscreen.png',
    exitScreenIcon: 'assets/images/preview-icons/exit-full-screen.png',
    appLogo: 'assets/images/app_logo.png',
    darkTheme: 'assets/themes/dark.css',
    lightTheme: 'assets/themes/light.css',
  },
  mapbox: {
    accessToken:
      'pk.eyJ1IjoicGV0ZXJicmF1bmVyIiwiYSI6ImNqeXpsYWJ0ZzAycXkzZGxqOGF0Ym1lcGMifQ.oI_w6Y8fdb14OsP78lonOw',
    geocodingApi: 'https://api.mapbox.com/geocoding/v5/mapbox.places/',
  },
  mqtt: {
    root: 'designage',
  },
  cloudinary: {
    cloudName: 'designage',
    uploadWidgetParameters: {
      preset: 'ml_default',
      apiKey: '262642945589628',
      sources: ['local'],
      folder: 'orphan',
      multiple: true,
      autoMinimize: false,
      styles: {
        palette: {
          window: '#36404a',
          windowBorder: '#90A0B3',
          tabIcon: '#0078FF',
          menuIcons: '#5A616A',
          textDark: '#000000',
          textLight: '#FFFFFF',
          link: '#0078FF',
          action: '#FF620C',
          inactiveTabIcon: '#0E2F5A',
          error: '#F44235',
          inProgress: '#0078FF',
          complete: '#20B832',
          sourceBg: '#414d59',
        },
        fonts: {
          default: {
            active: true,
          },
        },
      },
      clientAllowedFormats: [
        'jpg',
        'jpeg',
        'png',
        'gif',
        'tiff',
        'psd',
        'eps',
        'ai',
        'indd',
        'mp4',
        'mov',
        'wmv',
        'webm',
        'mpg',
        'mpeg',
        'mpe',
        'mpv',
        'avi',
        'flv',
        'swf',
        'mov',
        'qt',
        'pdf',
      ],
      language: 'default',
      text: {
        default: {
          queue: {
            title: 'UPLOAD_QUEUE',
            title_uploading_with_counter: 'UPLOADING_FILES_COUNTER',
            upload_more: 'UPLOAD_MORE',
          },
        },
      },
    },
  },
  file: {
    size: {
      image: 20000000,
      video: 500000000,
    },
    formats: [
      'jpg',
      'jpeg',
      'png',
      'gif',
      'tiff',
      'psd',
      'eps',
      'ai',
      'indd',
      'mp4',
      'mov',
      'wmv',
      'webm',
      'mpg',
      'mpeg',
      'mpe',
      'mpv',
      'avi',
      'flv',
      'swf',
      'mov',
      'qt',
      'html',
      'pdf',
    ],
  },
  timing: {
    pollInterval: 30,
  },
};
