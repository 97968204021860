const ACTIVE_PROFILE_ID = 'active-profile-id';
const PREFERRED_PAGE_SIZE = 'preferred-page-size';
const PREFERRED_PAGE = 'preferred-page';
const ASSETS_TO_EMULATE = 'assets-to-emulate';
const PREVIEW_ASSETS_TO_EMULATE = 'preview-assets-to-emulate';
const SIMULATED_DATE = 'simulated-date';
const PLAYLIST_SCHEDULE_STATUS = 'playlist-schedule-status';
const PENDING_PLAYLISTS = 'pending-playlists';
const CURRENT_USER = 'current-user';
const CURRENT_PROFILE = 'current-profile';
const TOKEN = 'token';
const ZONE_RESOURCES = 'zone-resources';
const ZONE_RUNTIME_SECONDS = 'zone-runtime-seconds';
const ZONE_ITEMS = 'zone-items';
const DEVICE_PREVIEWER_SETTINGS = 'device-previewer-settings';
const UI_SETTINGS = 'ui-settings';
const DEFAULT_PLAYLIST_DISPLAYS = 'default-playlist-displays';
const ACTIVE_PLAYLIST_ID = 'active-playlist-id';
const ACTIVE_DEVICE_ID = 'active-device-id';
const ACTIVE_PREVIEW_RESOLUTION = 'active-preview-resolution';
const SLIDING_PANEL = 'sliding-panel-setting';
const PLAYLIST_SETTINGS = 'playlist-settings';
const WATCHTOWER_VIEW = 'watchtower-view';
const WATCHTOWER_SPLIT_MODE = 'watchtower-split-mode';
const WATCHTOWER_FILTERS = 'watchtower-filters';
const WATCHTOWER_PANELS = 'watchtower-panels';
const WATCHTOWER_VIEW_MODE = 'watchtower-view-mode';
const WATCHTOWER_DEVICE_DATA_SELECTORS = 'wt-device-data-selectors';
const SORT_OPTION = 'sort-option';
const THEME = 'theme';
const INITIAL_ROUTE = 'initial-route';
const DATA_TABLES_SORT_SETTINGS = 'data-tables-sort-settings';
const SIDEBAR_COLLAPSED = 'sidebar-collapsed';
const USER_UI_MODE = 'user-ui-mode';

export const localStorageKeys = {
  ACTIVE_PROFILE_ID,
  PREFERRED_PAGE_SIZE,
  PREFERRED_PAGE,
  ASSETS_TO_EMULATE,
  PREVIEW_ASSETS_TO_EMULATE,
  SIMULATED_DATE,
  PLAYLIST_SCHEDULE_STATUS,
  PENDING_PLAYLISTS,
  CURRENT_USER,
  CURRENT_PROFILE,
  TOKEN,
  ZONE_RESOURCES,
  ZONE_RUNTIME_SECONDS,
  ZONE_ITEMS,
  DEVICE_PREVIEWER_SETTINGS,
  UI_SETTINGS,
  DEFAULT_PLAYLIST_DISPLAYS,
  ACTIVE_PLAYLIST_ID,
  ACTIVE_DEVICE_ID,
  ACTIVE_PREVIEW_RESOLUTION,
  SLIDING_PANEL,
  PLAYLIST_SETTINGS,
  WATCHTOWER_VIEW,
  WATCHTOWER_VIEW_MODE,
  WATCHTOWER_FILTERS,
  WATCHTOWER_SPLIT_MODE,
  WATCHTOWER_PANELS,
  WATCHTOWER_DEVICE_DATA_SELECTORS,
  SORT_OPTION,
  THEME,
  INITIAL_ROUTE,
  DATA_TABLES_SORT_SETTINGS,
  SIDEBAR_COLLAPSED,
  USER_UI_MODE,
};
