import { Injectable, computed, effect, inject, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  ISplit,
  IChannelFilters,
  ISortOrderDirection,
} from '@desquare/interfaces';
import { LocalStorageService } from 'ngx-webstorage';
import { DeviceData } from '@desquare/types';
import { deviceDataSelector, DeviceDataSelectorProps } from '@desquare/models';
import { getAppName } from '../session/session';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class UiDataService {
  wtSelectedDevicesForDetailPanel = signal<DeviceData[]>([]);

  defaultSortOrderConfigMap: Map<string, ISortOrderDirection>;

  defaultStatusFilters: IChannelFilters;

  lsKeyViewMode = 'viewMode';
  lsKeyStatusFilters = 'statusFilters';
  lsKeySelectedDevices = 'selectedDevices';
  viewMode!: ISplit;
  deviceFilter!: IChannelFilters;
  deviceDataSelector$ = new BehaviorSubject<DeviceDataSelectorProps[]>([]);

  private viewModeMessage$ = new BehaviorSubject<ISplit>(this.viewMode);
  private deviceFilterMessage$ = new BehaviorSubject<IChannelFilters>(
    this.deviceFilter
  );
  private selectedForDetailPanel$ = new BehaviorSubject<DeviceData[]>([]);

  currentStatusFilter = toSignal(this.deviceFilterMessage$.asObservable());
  currentStatusFilter$ = this.deviceFilterMessage$;
  ViewModeObservable$ = this.viewModeMessage$.asObservable();

  get isCms() {
    return getAppName() === 'cms';
  }

  constructor(private localStorageService: LocalStorageService) {
    this.defaultSortOrderConfigMap = new Map<string, ISortOrderDirection>();
    this.defaultStatusFilters = {
      noDeviceChannels: false,
      onlineChannels: true,
      offlineChannels: true,
      filterText: '',
      sortOrderConfigMap: this.defaultSortOrderConfigMap,
    };

    this.defaultSortOrderConfigMap.set('name', 'asc');

    this.deviceFilter = this.getStatusFilters();
    if (!this.deviceFilter.sortOrderConfigMap) {
      this.deviceFilter.sortOrderConfigMap = this.defaultSortOrderConfigMap;
    }
  }

  searchDevice(text: string) {
    this.deviceFilter.filterText = text;
    this.deviceFilterMessage$.next(this.deviceFilter);
  }

  sortDevice(fieldName: string, direction: ISortOrderDirection) {
    if (!this.deviceFilter.sortOrderConfigMap) {
      this.deviceFilter.sortOrderConfigMap = new Map<
        string,
        ISortOrderDirection
      >();
    }
    try {
      this.deviceFilter.sortOrderConfigMap.set(fieldName, direction);
    } catch {
      this.deviceFilter.sortOrderConfigMap = new Map<
        string,
        ISortOrderDirection
      >();
      this.deviceFilter.sortOrderConfigMap.set(fieldName, direction);
    }
    this.deviceFilterMessage$.next(this.deviceFilter);
  }

  getSelectedDeviceData() {
    let data = this.localStorageService.retrieve('deviceProperties');

    if (data === null) {
      this.deviceDataSelector$.next(deviceDataSelector);
      return this.deviceDataSelector$;
    } else if (data.length != deviceDataSelector.length) {
      console.log('data.length', data.length);
      console.log('deviceDataSelector.length', deviceDataSelector.length);

      this.deviceDataSelector$.next(deviceDataSelector);
      return this.deviceDataSelector$;
    }
    this.deviceDataSelector$.next(data);
    return this.deviceDataSelector$;
  }

  setSelectedDeviceData(row: number, event: boolean) {
    const selectedData = this.deviceDataSelector$.getValue();
    selectedData[row].visible = event;
    this.localStorageService.store('deviceProperties', selectedData);
    this.deviceDataSelector$.next([...selectedData]);
  }

  getSelectedForDetailsPanel() {
    const data = this.localStorageService.retrieve('selectedForDetail');
    if (data) this.selectedForDetailPanel$.next(data);

    return this.selectedForDetailPanel$;
  }
  // Selected Channel Observable
  updateSelectedDevices(device: DeviceData) {
    const currentSelected = this.wtSelectedDevicesForDetailPanel();
    const isSelected = currentSelected.findIndex((d) => d.id === device.id);
    if (isSelected != -1) {
      currentSelected.splice(isSelected, 1);
    } else {
      currentSelected.push(device);
    }
    this.localStorageService.store('selectedForDetail', currentSelected);
    this.wtSelectedDevicesForDetailPanel.set([...currentSelected]);
    // this.selectedForDetailPanel$.next(currentSelected);
  }

  closeSelectedDevicePanel() {
    this.localStorageService.clear('selectedForDetail');
    this.wtSelectedDevicesForDetailPanel.set([]);
  }

  // ViewMode Observable
  getViewMode() {
    return this.viewModeMessage$;
  }

  updateViewMode(viewMode?: ISplit) {
    this.localStorageService.store(this.lsKeyViewMode, viewMode);
    if (viewMode) {
      this.viewModeMessage$.next(viewMode);
    } else {
      this.viewModeMessage$.next(this.viewModeMessage$.getValue());
    }
  }

  // statusFilter Observable
  updatestatusFilter(statusFilter: IChannelFilters) {
    this.localStorageService.store(this.lsKeyStatusFilters, statusFilter);
    this.deviceFilterMessage$.next(statusFilter);
  }

  getStatusFilters(): IChannelFilters {
    let data = this.localStorageService.retrieve(this.lsKeyStatusFilters);
    if (data) {
      return data;
    }
    return this.defaultStatusFilters;
  }
}
