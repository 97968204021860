export interface DeviceDataSelectorProps {
  prop: string;
  name: string;
  visible: boolean;
}

export interface DeviceDataSelectorNetworkAdaptors {
  disabled?: boolean;
  dns?: string[];
  gateway?: string;
  localAdress?: string;
  netmask?: string;
  macAdress: string;
  name: string;
  type: string;
  wifiSsid?: string | null;
  wifiStrength?: number | null;
}

export interface DeviceDataSelectors {
  deviceName: DeviceDataSelectorProps;
  appletVersion: DeviceDataSelectorProps;
  applicationType: DeviceDataSelectorProps;
  applicationVersion: DeviceDataSelectorProps;
  location: DeviceDataSelectorProps;
  deviceTime: DeviceDataSelectorProps;
  firmware: DeviceDataSelectorProps;
  serialNumber: DeviceDataSelectorProps;
  networkAdapters: DeviceDataSelectorProps[];
  volume: DeviceDataSelectorProps;
}

export const deviceDataSelector: DeviceDataSelectorProps[] = [
  { prop: 'name', name: 'DEVICE_NAME', visible: false },
  { prop: 'location.name', name: 'LOCATION', visible: true },
  { prop: 'deviceInfo.applicationType', name: 'DEVICE_TYPE', visible: true },
  { prop: 'deviceInfo.model', name: 'DEVICE_MODEL', visible: true },
  { prop: 'deviceInfo.networkAdapters', name: 'IP', visible: true },
  {
    prop: 'deviceInfo.serialNumber',
    name: 'SERIAL',
    visible: false,
  },
  {
    prop: 'deviceInfo.firmware',
    name: 'FIRMWARE',
    visible: false,
  },
  {
    prop: 'deviceInfo.appletVersion',
    name: 'APPLET_VERSION',
    visible: true,
  },
  {
    prop: 'deviceInfo.applicationVersion',
    name: 'CORE_APP_VERSION',
    visible: true,
  },
  {
    prop: 'deviceInfo.volume',
    name: 'DEVICE_VOLUME',
    visible: false,
  },
  {
    prop: 'deviceInfo.currentTime.currentDate',
    name: 'DEVICE_TIME',
    visible: false,
  },
  {
    prop: 'deviceInfo.currentTime.currentDate',
    name: 'DEVICE_DATE',
    visible: false,
  },
  {
    prop: 'updatedAt',
    name: 'DEVICE_TIMESTAMP',
    visible: true,
  },
];
