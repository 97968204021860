export enum Permissions {
  READ_USERS = 'read:users',
  CREATE_USERS = 'create:users',
  UPDATE_USERS = 'update:users',
  DELETE_USERS = 'delete:users',

  READ_ANYTHING = 'read:anything',
  CREATE_ANYTHING = 'create:anything',
  UPDATE_ANYTHING = 'update:anything',
  DELETE_ANYTHING = 'delete:anything',

  READ_PROFILES = 'read:profiles',
  CREATE_PROFILES = 'create:profiles',
  UPDATE_PROFILES = 'update:profiles',
  DELETE_PROFILES = 'delete:profiles',

  READ_CHANNELS = 'read:channels',
  CREATE_CHANNELS = 'create:channels',
  UPDATE_CHANNELS = 'update:channels',
  DELETE_CHANNELS = 'delete:channels',

  READ_DEVICES = 'read:devices',
  CREATE_DEVICES = 'create:devices',
  UPDATE_DEVICES = 'update:devices',
  DELETE_DEVICES = 'delete:devices',

  READ_LOCATIONS = 'read:locations',
  CREATE_LOCATIONS = 'create:locations',
  UPDATE_LOCATIONS = 'update:locations',
  DELETE_LOCATIONS = 'delete:locations',

  READ_MEDIA = 'read:media',
  CREATE_MEDIA = 'create:media',
  UPDATE_MEDIA = 'update:media',
  DELETE_MEDIA = 'delete:media',

  EDIT_MEDIA = 'media :edit',
  EDIT_TEMPLATE = 'template:edit',

  READ_VIEWS = 'read:views',
  CREATE_VIEWS = 'create:views',
  UPDATE_VIEWS = 'update:views',
  DELETE_VIEWS = 'delete:views',

  READ_PLAYLISTS = 'read:playlists',
  CREATE_PLAYLISTS = 'create:playlists',
  UPDATE_PLAYLISTS = 'update:playlists',
  DELETE_PLAYLISTS = 'delete:playlists',

  READ_ORGANIZATIONS = 'read:organizations',
  CREATE_ORGANIZATIONS = 'create:organizations',
  UPDATE_ORGANIZATIONS = 'update:organizations',
  DELETE_ORGANIZATIONS = 'delete:organizations',

  READ_CHANNEL_GROUPS = 'read:channel_groups',
  CREATE_CHANNEL_GROUPS = 'create:channel_groups',
  UPDATE_CHANNEL_GROUPS = 'update:channel_groups',
  DELETE_CHANNEL_GROUPS = 'delete:channel_groups',

  UPDATE_ROLES = 'update:roles',
  UPGRADE_ROLES = 'upgrade:roles',
}
