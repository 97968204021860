import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '@desquare/environments';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  constructor() {}

  encrypt(value: string) {
    const encryptedValue = CryptoJS.AES.encrypt(
      value,
      environment.secret
    ).toString();
    return encryptedValue;
  }

  decrypt(value: string) {
    const decryptedValue = CryptoJS.AES.decrypt(
      value,
      environment.secret
    ).toString(CryptoJS.enc.Utf8);
    return decryptedValue;
  }
}
