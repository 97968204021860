import { EventEmitter, Injectable, Output } from '@angular/core';
import {
  DeleteProfileGQL,
  DestroyAllCloudyMediaGQL,
  GetProfileDetailedGQL,
  PurgeProfileGQL,
} from '@designage/gql';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(
    public getProfile: GetProfileDetailedGQL,
    private deleteProfile: DeleteProfileGQL,
    public destroy: DestroyAllCloudyMediaGQL,
    public purge: PurgeProfileGQL
  ) {}
  /** tell opened list components to refresh */
  @Output() profileListChanged = new EventEmitter(); // <boolean>();
}
