import { WritableSignal, signal } from '@angular/core';
import { DeviceInfo, Maybe, MeProfileFragment } from '@designage/gql';
import { IUser, IProfile } from '@desquare/interfaces';
import { DeviceStatusInfo } from '@desquare/models';

let appName: 'cms' | 'wt' = 'cms';
export const getAppName = () => {
  return appName;
};
export const setAppName = (value: 'cms' | 'wt') => {
  appName = value;
};

export const sessionUserProfiles: WritableSignal<
  Maybe<Array<MeProfileFragment>>
> = signal(undefined);

export const sessionUser: WritableSignal<Maybe<IUser>> = signal(undefined);

export const sessionProfile: WritableSignal<Maybe<IProfile>> =
  signal(undefined);

export const deviceStatusState: Map<
  string,
  WritableSignal<DeviceStatusInfo>
> = new Map();

export const deviceInfoState: Map<
  string,
  WritableSignal<Maybe<DeviceInfo>>
> = new Map();
