import { props, createAction } from '@ngrx/store';
import { IUser } from '@desquare/interfaces';
import { PaginatedProfiles } from '@designage/gql';

export enum UserActionTypes {
  LOAD_ACTIVE_USER = 'LOAD_ACTIVE_USER',
  LOAD_ACTIVE_USER_ERROR = 'LOAD_ACTIVE_USER_ERROR',
  SET_ACTIVE_USER = 'SET_ACTIVE_USER',
  LOAD_ACTIVE_USER_PROFILES = 'LOAD_ACTIVE_USER_PROFILES',
  LOAD_ACTIVE_USER_PROFILES_ERROR = 'LOAD_ACTIVE_USER_PROFILES_ERROR',
  SET_ACTIVE_USER_PROFILES = 'SET_ACTIVE_USER_PROFILES',
}

const loadActiveUser = createAction(
  UserActionTypes.LOAD_ACTIVE_USER,
  props<{ token: string; user: IUser }>()
);
const setActiveUser = createAction(
  UserActionTypes.SET_ACTIVE_USER,
  props<{ user: IUser }>()
);
const loadActiveUserError = createAction(
  UserActionTypes.LOAD_ACTIVE_USER_ERROR,
  props<{ error: any }>()
);

const loadActiveUserProfiles = createAction(
  UserActionTypes.LOAD_ACTIVE_USER_PROFILES,
  props<{ page?: number; pageSize?: number }>()
);
const setActiveUserProfiles = createAction(
  UserActionTypes.SET_ACTIVE_USER_PROFILES,
  props<{ profiles: PaginatedProfiles }>()
);
const loadActiveUserProfilesError = createAction(
  UserActionTypes.LOAD_ACTIVE_USER_PROFILES_ERROR,
  props<{ error: any }>()
);

export const userActions = {
  loadActiveUser,
  setActiveUser,
  loadActiveUserError,
  loadActiveUserProfiles,
  loadActiveUserProfilesError,
  setActiveUserProfiles,
};
